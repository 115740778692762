<template>
  <a-card :bordered="false">
    <div>
      <div class="table-operator">
        <a-page-header
          style="border: 1px solid rgb(235, 237, 240)"
          title="查看记录"
          @back="handleGoBack"
        ></a-page-header>
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
      </s-table>
    </div>
  </a-card>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { car_read } from '@/api/car'
import { Dictionaries } from '@/common/AllConstant'

export default {
  name: 'Read',
  components: {
    STable
  },
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '企业名字',
          dataIndex: 'corporation',
          ellipsis: true
        },
        {
          title: '车牌号',
          dataIndex: 'number'
        },
        {
          title: '创建人',
          dataIndex: 'create_by'
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          ellipsis: true
        },
        {
          title: '操作',
          customRender: (text) => this.$Dictionaries.RecordAction[text],
          dataIndex: 'action'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return car_read(Object.assign(parameter, this.queryParam, { number: this.$route.params.number }))
          .then(res => {
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
  },
  methods: {
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
