import request from '@/utils/request'

const carApi = {
  car_list: '/car/',
  car_create: '/car/',
  car_read: '/car/record/',
  car_delete: '/car/'
}

/**
 * 获取授权车辆列表
 */
export function car_list (parameter) {
  return request({
    url: carApi.car_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function car_create (parameter) {
  return request({
    url: carApi.car_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 详情
 */
export function car_read (parameter) {
  return request({
    url: carApi.car_read,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 删除
 */
export function car_delete (car_id) {
  return request({
    url: carApi.car_delete + car_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
